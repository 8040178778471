<template>
  <div id="noticeModal" class="modal-wrap">
    <div class="notice_wrap">
      <p class="title">{{ $t("commonConfirmMessage.isDelete") }}</p>
      <p class="txt">{{ $t("commonMessage.afterDeletion") }}</p>
    </div>
    <div class="modal_btn">
      <a class="btn_b close-reveal-modal" @click="onClickClose">
        {{ $t("common.cancel") }}
      </a>
      <a class="btn_p close-reveal-modal" @click="onClickSubmit">
        {{ $t("common.submit") }}
      </a>
    </div>
  </div>
</template>

<script>
import ProjectService from "@/service/ProjectService";

export default {
  name: "PartnerDeletePopup",
  data() {
    return {
      reservationId: null,
      sno: null,
    };
  },
  methods: {
    showPopup(reservationId, sno) {
      this.reservationId = reservationId;
      this.sno = sno;
    },
    onClickClose() {
      this.$emit("close");
    },
    async onClickSubmit() {
      try {
        const status = await ProjectService.deleteReservationCompanion(
          this.reservationId,
          this.sno
        );
        this.$emit(status === "OK" ? "submit" : "close");
      } catch (e) {
        this.$emit("close");
        throw new Error(e);
      }
    },
  },
};
</script>
