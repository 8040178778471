<template>
  <div id="okModal" class="modal-wrap">
    <div class="popup_ok_wrap">
      <span class="img_join"></span>
      <p class="title">{{ $t("companionMessage.finishedRegister") }}</p>
    </div>
    <div class="modal_btn">
      <a class="btn_close close-reveal-modal" @click="onClickSubmit">
        {{ $t("common.submit") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnerOkPopup",
  methods: {
    onClickSubmit() {
      this.$emit("submit");
    },
  },
};
</script>
