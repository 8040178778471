import ProjectService from "@/service/ProjectService";
import dayjs from "dayjs";
import {
  getCurrentSessionGrant,
  getCurrentSessionResveId,
  getCurrentSessionSno,
} from "@/utils/session/SessionUtil";
import PartnerOkPopup from "@/components/reservations/popup/PartnerOkPopup";
import PartnerRegisterPopup from "@/components/reservations/popup/PartnerRegisterPopup";
import PartnerModifyPopup from "@/components/reservations/popup/PartnerModifyPopup";
import PartnerDeletePopup from "@/components/reservations/popup/PartnerDeletePopup";
import {
  getCourseName,
  getReservationChannelName,
  getSexName,
} from "@/mixins/commonCodesMixin";
import { toHyphenedPhoneNumber } from "@/utils/string";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PartnerOkPopup,
    PartnerRegisterPopup,
    PartnerModifyPopup,
    PartnerDeletePopup,
  },
  data() {
    return {
      reservationId: getCurrentSessionResveId(),
      reservationInfo: null,
      reservationCmpnList: [],
      visitTeamInfo: null,
      visitTeamCmpnList: [],
      reservationCmpnMoreList: [],
      availableCancelDateTime: null,
      isEqualsFirstCompanion: null,
      visitCount: 4,
      checkInKey: null,
      isBooker: getCurrentSessionGrant() === "S_FRONT_BOOKER",
      isVisitor: getCurrentSessionGrant() === "S_VISITOR",
      isLockerOkPopupOpened: false,
      isPartnerOkPopupOpened: false,
      isPartnerRegisterPopupOpened: false,
      isPartnerModifyPopupOpened: false,
      isPartnerDeletePopupOpened: false,
    };
  },
  async created() {
    this.checkInKey = this.isBooker ? 1 : getCurrentSessionSno();
    await this.fetch();
  },
  computed: {
    ...mapGetters(["goCheckIn"]),
    isPopupOpened() {
      return (
        this.isLockerOkPopupOpened ||
        this.isPartnerOkPopupOpened ||
        this.isPartnerDeletePopupOpened ||
        this.isPartnerModifyPopupOpened ||
        this.isPartnerRegisterPopupOpened
      );
    },
    isCheckedIn() {
      return this.signedInCmpnMemberInfo?.isCheckedIn;
    },
    isReservationComplete() {
      return this.reservationInfo?.resveStatus === "RESVE";
    },
    viewCmpnList() {
      return this.isVisitor ? this.visitTeamCmpnList : this.reservationCmpnList;
    },
    signedInCmpnMemberInfo() {
      return this.isVisitor
        ? this.visitTeamCmpnList?.find(
            (member) => member.visitId === this.checkInKey
          )
        : this.reservationCmpnList?.find(
            (member) => member.sno === this.checkInKey
          );
    },
    signedInCmpnMemberName() {
      return (
        this.signedInCmpnMemberInfo?.cmpnName ||
        this.signedInCmpnMemberInfo?.visitName
      );
    },
    signedInCmpnSexName() {
      return getSexName(this.signedInCmpnMemberInfo?.sexCode);
    },
    signedInCmpnMemberLockerNo() {
      return this.signedInCmpnMemberInfo?.lockerNo;
    },
    reservationTimeInfo() {
      return this.isVisitor
        ? this.visitTeamInfo
        : this.reservationInfo?.tgResveTime;
    },
    reservationDateHeaderLabel() {
      return dayjs(
        this.reservationTimeInfo?.resveDate ||
          this.reservationTimeInfo?.visitDate
      ).format("YYYY. MM. DD (ddd)");
    },
    reservationDateHeaderSubLabel() {
      return (
        this.reservationTimeInfo?.resveTime ||
        this.reservationTimeInfo?.startTime
      );
    },
    reservationCourseHeaderLabel() {
      return getCourseName(
        this.reservationTimeInfo?.resveCourse ||
          this.reservationTimeInfo?.startCourse
      );
    },
    reservationNo() {
      return this.reservationInfo?.resveNo;
    },
    resveNoByKiosk() {
      const resveTime = this.reservationInfo?.tgResveTime?.resveTime
        ? this.reservationInfo.tgResveTime.resveTime.replaceAll(":", "")
        : null;
      const resveCourse = this.reservationInfo.tgResveTime.resveCourse;

      let resveNo = "";
      if (resveTime && resveCourse) {
        resveNo = resveTime.concat(resveCourse);
      }

      return resveNo;
    },
    reservationStateClass() {
      return this.isReservationComplete ? "state_ok" : "state_standby";
    },
    reservationStateLabel() {
      return this.$t(
        this.isReservationComplete
          ? "main.reservationComplete"
          : "main.reservationWaiting"
      );
    },
    reservationMemberInfo() {
      return this.reservationInfo?.tgResveMember;
    },
    reservationMemberNo() {
      return this.reservationMemberInfo?.memberNo;
    },
    reservationMemberNameAndPhoneNumber() {
      const reservationName = this.reservationMemberInfo?.resveName;
      const phoneNumber = toHyphenedPhoneNumber(
        this.reservationMemberInfo?.contactTel
      );
      return `${reservationName}${phoneNumber ? ` ${phoneNumber}` : ""}`;
    },
    reservationChannel() {
      return getReservationChannelName(
        this.reservationMemberInfo?.resveChannel
      );
    },
  },
  methods: {
    ...mapActions(["setPayerName", "setGoCheckIn"]),
    reservationSexClass(sexCode) {
      return sexCode === "M" ? "man" : "woman";
    },
    onLockerOkPopupSubmit() {
      this.isLockerOkPopupOpened = false;
    },
    onPartnerOkPopupSubmit() {
      this.isPartnerOkPopupOpened = false;
    },
    async onClickAddPartner() {
      if (this.isPopupOpened || !this.isBooker) {
        return;
      }
      this.isPartnerRegisterPopupOpened = true;
      await this.$nextTick();
      this.$refs.partnerRegisterPopup.showPopup(this.reservationId);
    },
    onPartnerRegisterPopupClose() {
      this.isPartnerRegisterPopupOpened = false;
    },
    async onPartnerRegisterPopupSubmit() {
      this.onPartnerRegisterPopupClose();
      await this.fetch();
      this.isPartnerOkPopupOpened = true;
    },
    async onClickModifyPartner({ sno, contactTel }) {
      if (this.isVisitor) return;
      const selectedCmpn = this.reservationCmpnList?.find(
        (member) => member.sno === sno
      );
      if (
        this.isPopupOpened ||
        (this.isEqualsFirstCompanion === false && sno === 1) ||
        !this.isBooker ||
        selectedCmpn.isCheckedIn ||
        selectedCmpn.memberNo === this.reservationMemberNo
      ) {
        return;
      }
      this.isPartnerModifyPopupOpened = true;
      await this.$nextTick();
      this.$refs.partnerModifyPopup.showPopup(
        this.reservationId,
        sno,
        contactTel
      );
    },
    onPartnerModifyPopupClose() {
      this.isPartnerModifyPopupOpened = false;
    },
    async onPartnerModifyPopupSubmit({ sno, contactTel }) {
      this.onPartnerModifyPopupClose();
      await this.resendSMS(sno, contactTel);
    },
    async onClickDeletePartner(sno) {
      if (this.isPopupOpened || !this.isBooker) {
        return;
      }
      this.isPartnerDeletePopupOpened = true;
      await this.$nextTick();
      this.$refs.partnerDeletePopup.showPopup(this.reservationId, sno);
    },
    onPartnerDeletePopupClose() {
      this.isPartnerDeletePopupOpened = false;
    },
    async onPartnerDeletePopupSubmit() {
      this.onPartnerDeletePopupClose();
      await this.fetch();
    },
    closeAllPopup() {
      this.onPartnerOkPopupSubmit();
      this.onPartnerRegisterPopupClose();
      this.onPartnerModifyPopupClose();
      this.onPartnerDeletePopupClose();
    },
    async fetch() {
      const { availableCancelDateTime, tgResveConfirm } =
        await ProjectService.fetchReservation(this.reservationId);
      this.reservationInfo = tgResveConfirm;
      this.reservationCmpnList = tgResveConfirm.tgResveCmpns;
      this.visitTeamInfo = tgResveConfirm.tgVisitTeams[0];
      this.visitTeamCmpnList = this.visitTeamInfo?.tgVisitCmpns; // TODO : 추후 단체 구분 필요 (현재 무조건 index 0)
      this.visitCount = tgResveConfirm.visitCnt;
      let reservationCmpnMoreList = [];
      if (this.isBooker) {
        for (
          let i = this.reservationCmpnList.length + 1;
          i <= this.visitCount;
          i++
        ) {
          reservationCmpnMoreList.push({ sno: i });
        }
      }
      this.reservationCmpnMoreList = reservationCmpnMoreList;
      this.reservationAvailableCancelDateTime = dayjs(
        availableCancelDateTime
      ).format("YYYY.MM.DD HH:mm");
      await this.setPayerName(this.signedInCmpnMemberName);
      if (this.isVisitor || this.goCheckIn) {
        await this.setGoCheckIn();
        await this.goCheckInPage(this.isVisitor);
      }
    },
    async resendSMS(sno, contactTel = null) {
      try {
        const status = await ProjectService.resendReservaionSMS(
          this.reservationId,
          sno,
          contactTel
        );
        if (status === "OK") {
          await this.fetch();
          await this.confirm({
            content: this.$t("smsMessage.succeedSendSMS"),
          });
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
