<template>
  <div id="partnerModal" class="modal-wrap">
    <div class="partner_wrap">
      <p class="stitle">{{ $t("companionMessage.enterCompanion") }}</p>
      <div class="partner_add">
        <div class="mg_b10">
          <label>{{ $t("common.name") }}</label>
          <input
            type="text"
            ref="cmpnName"
            v-model.trim="cmpnName"
            :placeholder="$t('companionMessage.enterCompanionNameAbbr')"
          />
        </div>
        <label>{{ $t("common.cellphone") }}</label>
        <input-phone-number
          ref="contactTel"
          :pure-phone-number.sync="contactTel"
        />
      </div>
      <ul class="sex_select">
        <li>
          <input
            class="radio_sex man"
            type="radio"
            name="sex_select"
            value="M"
            v-model="sexCode"
          />
          <label>{{ $t("common.male") }}</label>
        </li>
        <li>
          <input
            class="radio_sex woman"
            type="radio"
            name="sex_select"
            value="F"
            v-model="sexCode"
          />
          <label>{{ $t("common.female") }}</label>
        </li>
      </ul>
      <p class="check_info">
        <span class="ico" />
        {{ $t("companionMessage.companionNotify") }}
      </p>
    </div>
    <div class="modal_btn">
      <a class="btn_b close-reveal-modal" @click="onClickClose">
        {{ $t("common.cancel") }}
      </a>
      <a class="btn_p close-reveal-modal" @click="onClickSubmit">
        {{ $t("common.insert") }}
      </a>
    </div>
  </div>
</template>

<script>
import InputPhoneNumber from "@/components/common/InputPhoneNumber";
import ProjectService from "@/service/ProjectService";

export default {
  name: "PartnerRegisterPopup",
  components: { InputPhoneNumber },
  data() {
    return {
      reservationId: null,
      cmpnName: "",
      contactTel: this.$t("commonPlaceholder.contactTelPrefix"),
      sexCode: null,
    };
  },
  async mounted() {
    await this.$nextTick();
    this.$refs.cmpnName.focus();
  },
  methods: {
    showPopup(reservationId) {
      this.reservationId = reservationId;
    },
    onClickClose() {
      this.$emit("close");
    },
    async onClickSubmit() {
      if (!this.cmpnName || this.cmpnName === "") {
        await this.confirm({
          content: this.$t("commonMessage.enterName"),
          submitCallback: () => this.$refs.cmpnName.focus(),
        });
        return;
      }
      const contactTel = this.contactTel.replace(/-/gim, "");
      if (contactTel.length < 10) {
        await this.confirm({
          content: this.$t("commonMessage.enterCellphoneNumberAll"),
          submitCallback: () => this.$refs.contactTel.focusIn(),
        });
        return;
      }
      if (!this.sexCode) {
        await this.confirm({ content: this.$t("commonMessage.selectSex") });
        return;
      }
      try {
        const status = await ProjectService.addReservationCompanion(
          this.reservationId,
          this.cmpnName,
          contactTel,
          this.sexCode
        );
        this.$emit(status === "OK" ? "submit" : "close");
      } catch (e) {
        this.$emit("close");
        throw new Error(e);
      }
    },
  },
};
</script>
