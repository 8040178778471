import dayjs from "dayjs";

export function phoneNumberFormatter(data = "") {
  if (data.startsWith("050")) {
    if (4 < data.length) {
      if (data.length < 9) {
        return data.replace(/^([\d*]{4})([\d*]*)$/, "$1-$2");
      } else if (data.length < 13) {
        return data.replace(/^([\d*]{4})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  } else if (data.startsWith("02")) {
    if (2 < data.length) {
      if (data.length < 7) {
        return data.replace(/^([\d*]{2})([\d*]*)$/, "$1-$2");
      } else if (data.length < 11) {
        return data.replace(/^([\d*]{2})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  } else {
    if (3 < data.length) {
      if (data.length < 8) {
        return data.replace(/^([\d*]{3})([\d*]*)$/, "$1-$2");
      } else if (data.length < 12) {
        return data.replace(/^([\d*]{3})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  }

  switch (data.length) {
    case 9:
      return data.replace(/^([\d*]{2})([\d*]{3})([\d*]{4})$/, "$1-$2-$3");
    case 10:
      return data.replace(/^([\d*]{3})([\d*]{3})([\d*]{4})$/, "$1-$2-$3");
    case 11:
      return data.replace(/^([\d*]{3})([\d*]{4})([\d*]{4})$/, "$1-$2-$3");
    case 12:
      return data.replace(/^([\d*]{4})([\d*]{4})([\d*]{4})$/, "$1-$2-$3");
  }

  return data;
}

export default {
  filters: {
    datetimeToDate(datetime) {
      return dayjs(datetime).format("YYYY-MM-DD");
    },
    datetimeWithoutSeconds(datetime) {
      return dayjs(datetime).format("YYYY-MM-DD HH:mm");
    },
    dayStringOfWeek(data) {
      return ["일", "월", "화", "수", "목", "금", "토"][data];
    },
    phoneNumberFormatter(data) {
      return phoneNumberFormatter(data);
    },
    numberFormatter(data) {
      if (data === undefined || data === null) {
        return "0";
      }

      const amount = Number(data);

      if (!isNaN(amount)) {
        if (amount === Math.floor(amount)) {
          return amount.toLocaleString(undefined, {
            minimumFractionDigits: 0,
          });
        } else {
          return amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          });
        }
      }

      return `${data}`;
    },
  },
};
