import { getCommonCodesMap } from "@/utils/CommonCodeUtil";
import { mapGetters } from "vuex";

export function getCourseName(courseCode) {
  const { businessComCodesMap } = getCommonCodesMap();
  const courseCodes = businessComCodesMap?.get("COURSE_CODE");
  return (
    courseCodes?.find(({ comCode }) => comCode === courseCode)?.comName ||
    courseCode
  );
}

export function getMemberGrades() {
  const { commonComCodesMap } = getCommonCodesMap();
  return commonComCodesMap?.get("MEMBER_GRADE") || [];
}

export function getReservationChannelName(resveChannel) {
  const { businessComCodesMap } = getCommonCodesMap();
  const resveChannels = businessComCodesMap?.get("RESVE_CHANNEL");
  return (
    resveChannels?.find(({ comCode }) => comCode === resveChannel)?.comName ||
    resveChannel
  );
}

export function getHoleName(holeDiv) {
  const { businessComCodesMap } = getCommonCodesMap();
  const holeCodes = businessComCodesMap?.get("HOLE_DIV");
  return (
    holeCodes?.find(({ comCode }) => comCode === holeDiv)?.comName || holeDiv
  );
}

export function getTimeZones() {
  const { businessComCodesMap } = getCommonCodesMap();
  return businessComCodesMap?.get("TIME_ZONE") || [];
}

export function getSexName(sexCode) {
  const { commonComCodesMap } = getCommonCodesMap();
  const sexCodes = commonComCodesMap?.get("SEX_CODE");
  return (
    sexCodes?.find(({ comCode }) => comCode === sexCode)?.comName || sexCode
  );
}

export function getAreaCodes() {
  const { commonComCodesMap } = getCommonCodesMap();
  return commonComCodesMap?.get("AREA_CODE") || [];
}

export function getDpmnyKinds() {
  const { businessComCodesMap } = getCommonCodesMap();
  return businessComCodesMap?.get("DPMNY_KIND") || [];
}

export function getDpmnyStatuses() {
  const { businessComCodesMap } = getCommonCodesMap();
  return businessComCodesMap?.get("DPMNY_STATUS") || [];
}

export function getBankCodes() {
  const { businessComCodesMap } = getCommonCodesMap();
  return businessComCodesMap?.get("BANK_CODE") || [];
}

export default {
  filters: {
    courseName(courseCode) {
      return getCourseName(courseCode);
    },
    holeName(holeDiv) {
      const { businessComCodesMap } = getCommonCodesMap();
      const courseCodes = businessComCodesMap?.get("HOLE_DIV");
      return (
        courseCodes?.find(({ comCode }) => comCode === holeDiv)?.comName ||
        holeDiv
      );
    },
    crtfName(crtfCode) {
      const { businessComCodesMap } = getCommonCodesMap();
      const crtfCodes = businessComCodesMap?.get("CRTF_CODE");
      return (
        crtfCodes?.find(({ comCode }) => comCode === crtfCode)?.comName ||
        crtfCode
      );
    },
  },
  computed: {
    ...mapGetters(["comCodes"]),
  },
};
