<template>
  <input
    type="text"
    class="phone"
    ref="phoneNumber"
    v-model="phoneNumber"
    :placeholder="$t('commonMessage.enterCellphoneNumberAbbr')"
    :maxlength="maxlength"
    :autofocus="autofocus"
    @input="onKeyUpPhoneNumber"
    @keydown.enter="onKeyDownEnterPhoneNumber"
  />
</template>

<script>
import { phoneNumberFormatter } from "@/mixins/formatterMixin";

export default {
  name: "InputPhoneNumber",
  props: {
    purePhoneNumber: {
      type: String,
      default: null,
    },
    maxlength: {
      type: Number,
      default: 13,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneNumber: this.purePhoneNumber,
    };
  },
  watch: {
    purePhoneNumber(purePhoneNumber) {
      this.phoneNumber = purePhoneNumber;
    },
    phoneNumber(phoneNumber) {
      this.$emit("update:purePhoneNumber", phoneNumber);
    },
  },
  methods: {
    async onKeyUpPhoneNumber(event) {
      const {
        inputType,
        target: { value, maxLength: len, selectionStart: start },
      } = event;
      switch (inputType) {
        case "insertText":
          this.formattingPhoneNumber(len);
          await this.setFocusPhoneNumber(
            start + this.getCountHyphenByPhoneNumber(value, start)
          );
          break;
        case "deleteContentBackward":
          this.formattingPhoneNumber(len);
          await this.setFocusPhoneNumber(start);
          break;
      }
    },
    onKeyDownEnterPhoneNumber() {
      this.$emit("submit");
    },
    getCountHyphenByPhoneNumber(beforePhoneNumber, index) {
      const beforeCount = (beforePhoneNumber.match(/-/gim) || []).length;
      const afterCount = (this.phoneNumber.match(/-/gim) || []).length;
      const phoneNumber = this.phoneNumber.substring(0, index);
      const focusedCount = (phoneNumber.match(/-/gim) || []).length;
      return beforeCount >= afterCount ? 0 : focusedCount;
    },
    async setFocusPhoneNumber(index) {
      await this.$nextTick();
      this.$refs.phoneNumber.setSelectionRange(index, index);
    },
    formattingPhoneNumber(maxLength) {
      this.phoneNumber = phoneNumberFormatter(
        this.phoneNumber.replace(/[^0-9]/gim, "")
      );
      if (this.phoneNumber.length > maxLength) {
        this.phoneNumber = this.phoneNumber.slice(0, maxLength);
      }
    },
    focusIn() {
      this.$refs.phoneNumber.focus();
    },
    clear() {
      this.phoneNumber = this.$t("commonPlaceholder.contactTelPrefix");
      this.focusIn();
    },
  },
};
</script>
