<template>
  <div id="partnerModal" class="modal-wrap">
    <div class="partner_wrap">
      <p class="stitle stitle_small">
        {{ $t("companionMessage.toEditRegisterAgain") }}
      </p>
      <div class="partner_resend">
        <label>{{ $t("common.cellphone") }}</label>
        <input-phone-number
          ref="contactTel"
          :pure-phone-number.sync="contactTel"
          :disabled="hasContactTel"
          autofocus
        />
        <a @click="onClickResend">{{ sendSMSLabel }}</a>
      </div>
    </div>
    <div class="modal_btn">
      <a class="btn_close close-reveal-modal" @click="onClickSubmit">
        {{ $t("common.submit") }}
      </a>
    </div>
  </div>
</template>

<script>
import InputPhoneNumber from "@/components/common/InputPhoneNumber";
import { phoneNumberFormatter } from "@/mixins/formatterMixin";

export default {
  name: "PartnerModifyPopup",
  components: { InputPhoneNumber },
  data() {
    return {
      reservationId: null,
      sno: null,
      contactTel: this.$t("commonPlaceholder.contactTelPrefix"),
      hasContactTel: false,
    };
  },
  computed: {
    sendSMSLabel() {
      return this.hasContactTel
        ? this.$t("common.resendSMS")
        : this.$t("common.sendSMS");
    },
  },
  methods: {
    showPopup(reservationId, sno, contactTel) {
      this.reservationId = reservationId;
      this.sno = sno;
      if (contactTel) {
        this.contactTel = phoneNumberFormatter(contactTel);
        this.hasContactTel = true;
      }
    },
    async onClickResend() {
      const contactTel = this.contactTel.replace(/-/gim, "");
      if (contactTel.length < 10) {
        await this.confirm({
          content: this.$t("commonMessage.enterCellphoneNumberAll"),
          submitCallback: () => this.$refs.contactTel.focusIn(),
        });
        return;
      }
      this.$emit("submit", {
        sno: this.sno,
        contactTel: !this.hasContactTel ? contactTel : null,
      });
    },
    onClickSubmit() {
      this.$emit("close");
    },
  },
};
</script>
